import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { getDocs, where, collection, query  } from 'firebase/firestore';

const EmployeeDashboard = () => {
    const [userData, setUserData] = useState(null);
    let { uid } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
          try {
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('uid', '==', uid));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
              // Assuming the user data is in the first document
              const userDoc = querySnapshot.docs[0].data();
              setUserData(userDoc);
            } else {
              console.log('No user found with the given UID');
              navigate('/employees'); // If no user is found, redirect
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
            navigate('/employees'); // Redirect on error as well
          }
        };
    
        if (uid) {
          fetchUserData();
        }
    }, [uid, navigate]);

    return (
        <div className="relative">
            {userData ? (
                `Welcome ${userData.name}` 
            ) : (
                'Loading...' 
            )}
        </div>
    );
}

export default EmployeeDashboard;
