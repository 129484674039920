import React from "react";
import { signInWithGoogle } from "../../authUtils";
import backgroundImg from "../../assets/images/employeeWelcomeImage.jpg";

const ManagerWelcome = () => {
    const handleSignInClick = async () => {
        const user = await signInWithGoogle();
        if (user) {
          // User is signed in.
          // You can redirect the user or do other actions as needed.
        }
      };
  return (
    <div className="relative min-h-screen bg-black text-white">
      {/* Background Image */}
      <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImg})` }}></div>
      
      {/* Gray Transparent Overlay */}
      <div className="absolute inset-0 bg-gray-700 opacity-80"></div>
      
      {/* "Sign In" Button */}
      <div className="absolute top-0 right-0 m-6 z-50">
        <button onClick={handleSignInClick} className="text-sm bg-transparent border-2 border-white text-white py-2 px-4 hover:bg-white hover:text-gray-900 transition duration-300 ease-in-out rounded">
          Sign In
        </button>
      </div>

      {/* Content */}
      <div className="relative z-10 flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h1 className="text-8xl font-bold uppercase">Bartender</h1>
          <p className="text-3xl mt-4">Local Hire of a Mixologist made easy</p>
          <div className="mt-10">
            <button onClick={handleSignInClick} className="bg-orange-500 px-10 py-2 text-2xl rounded-full hover:bg-orange-600 transition duration-300">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagerWelcome;

