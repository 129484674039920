import React from "react";
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle } from "../../authUtils";
import backgroundImg from "../../assets/images/employeeWelcomeImage.jpg";

const EmployeeWelcome = () => {
  let navigate = useNavigate();

  const handleSignInClick = async () => {
      const user = await signInWithGoogle();
      if (user) {
          // Redirect to the dashboard
          const uid = user.uid;
          navigate(`/employees/${uid}`);
      }
  };
  return (
    <div className="relative bg-black text-white min-h-screen">
      {/* Background Image */}
      <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImg})` }}></div>
      
      {/* Gray Transparent Overlay */}
      <div className="absolute inset-0 bg-gray-700 opacity-80"></div>
      
      {/* "Sign In" Button */}
      <div className="absolute top-0 right-0 m-6 z-50">
        <button onClick={handleSignInClick} className="bg-transparent border-2 border-white text-white py-2 px-4 text-sm rounded hover:bg-white hover:text-gray-900 transition duration-300 ease-in-out">
          Sign In
        </button>
      </div>

      {/* Content */}
      <div className="flex items-center justify-center min-h-screen relative z-10">
        <div className="text-center px-4">
          <h1 className="text-4xl sm:text-8xl font-bold uppercase">Bartender</h1>
          <p className="text-xl sm:text-3xl mt-4">Local Gig Bartending Made Easy</p>
          <div className="mt-10">
            <button onClick={handleSignInClick} className="bg-orange-500 px-8 py-2 text-xl sm:text-2xl rounded-full hover:bg-orange-600 transition duration-300">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeWelcome;
