import React from "react";
import { Link } from 'react-router-dom';
import backgroundVideo from "./assets/bartending.mp4";

const LandingPage = () => {
    return (
        <div className="relative min-h-screen bg-black overflow-hidden">
            <video
                src={backgroundVideo}
                autoPlay
                loop
                muted
                className="absolute z-10 w-full h-full object-cover"
            />
            <div className="absolute z-20 w-full h-full bg-gray-700 opacity-50"></div>
            <div className="content absolute z-30 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                <h1 className="text-white text-6xl font-bold">WELCOME</h1>
                <p className="text-white text-3xl mt-4">TO BARTENDER</p>
                <div className="mt-8 flex flex-col sm:flex-row justify-center items-center gap-4">
                    <Link to="/employees" className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none">
                        Start Bartending
                    </Link>
                    <Link to="/managers" className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out focus:outline-none">
                        Hire a Mixologist
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
