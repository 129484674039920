import React from "react";

const EmployeeFindJobs = () => {
    return (
        <div>
        <h1>Employee Find Jobs</h1>
        </div>
    );
}

export default EmployeeFindJobs;