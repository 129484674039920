// authUtils.js
import { auth, db, googleProvider, signInWithPopup } from './firebase';
import { query, getDocs, collection, where, addDoc } from "firebase/firestore";

const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
            });
        }
        console.log(user);
        return user; // Return the user data
    } catch (err) {
        console.error(err);
        alert(err.message);
        return null; // Return null if there was an error
    }
};

export { signInWithGoogle };
