import React from 'react';
import { Outlet } from 'react-router-dom';
import EmployeeNavbar from './employeeNavbar';

const EmployeeLayout = () => {
    return (
      <>
        <EmployeeNavbar />
        <div className="container mx-auto mt-4">
          <Outlet />
        </div>
      </>
    );
  };
  
  export default EmployeeLayout;