import React from "react";

const EmployeeWorkHistory = () => {
    return (
        <div>
        <h1>Employee Work History</h1>
        </div>
    );
}

export default EmployeeWorkHistory;