import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Make sure to import 'react-router-dom'
import LandingPage from "./landing-page/landingPage";
import EmployeeWelcome from "./employees/screens/employeeWelcome";
import ManagerWelcome from "./managers/screens/managersWelcome";
import EmployeeLayout from './employees/components/employeeLayout';
import EmployeeDashboard from "./employees/screens/employeeDashboard";
import EmployeeSchedule from './employees/screens/employeeSchedule';
import EmployeeWorkHistory from './employees/screens/employeeWorkHistory';
import EmployeeFindJobs from './employees/screens/employeeFindJobs';
// import Layout from "./components/Layout";

function App() {

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />

      <Route path="/employees" element={<EmployeeWelcome/>}/>
      <Route path="/employees/:uid" element={<EmployeeLayout/>}>
        <Route index element={<EmployeeDashboard/>}/>
        <Route path="/employees/:uid/schedule" element={<EmployeeSchedule/>}/>
        <Route path="/employees/:uid/work-history" element={<EmployeeWorkHistory/>}/>
        <Route path="/employees/:uid/find-jobs" element={<EmployeeFindJobs/>}/>
      </Route>





      <Route path="/managers" element={<ManagerWelcome/>}/>



      {/* <Route exact path="/employees/dashboard/:uid" element={<EmployeeDashboard/>}/> */}


    </Routes>
  );
}

export default App;
